import { Account } from "@lib/ShiOneClient";
import { getPicklistValue } from "../../../../../functions/picklistValue";
import shiColors, { graphColors } from "shared-ui/src/theme/shiColors";
import { numberFormatterWithCurrency } from "shared-ui/src/numberFormatter";
import { percentageFormatter } from "shared-ui/src/functions/percentage";

export function getActiveAccounts(accounts: Account[] | undefined) {
  if (!accounts) {
    return [];
  }

  return accounts.filter((a) => a.active);
}

export function getAccountNamesFromAccounts(accounts: Account[] | undefined) {
  if (!accounts) {
    return [];
  }

  return accounts.map((a) => a.accountName!);
}

export function getBillingTypeLabel(
  contractType: number | undefined,
  picklist: any
) {
  return getPicklistValue(
    picklist.picklist,
    "contract",
    "contractType",
    contractType
  ).label;
}

export function getBillingTypeColor(
  contractType: number | undefined,
  picklist: any
) {
  const picklistValue = getPicklistValue(
    picklist.picklist,
    "contract",
    "contractType",
    contractType
  );

  if (!picklistValue) {
    return "";
  }

  const sortOrder = picklistValue.sortOrder;

  if (sortOrder < graphColors.length) {
    return graphColors[sortOrder];
  }

  return "";
}

export function getContractTypeNameColor(contractType: string | undefined) {
  switch (contractType?.toLowerCase()) {
    case "saas":
      return shiColors.pink.DEFAULT;
    case "service":
      return shiColors.blue.DEFAULT;
    default:
      return shiColors.gray.light;
  }
}

export function calculateContractDuration(
  start: Date | undefined,
  end: Date | undefined
) {
  if (!start || !end) {
    return 0;
  }

  const now = new Date();

  // Calculate how far we are into the contract in percentage
  if (now > end) {
    return 100;
  }

  if (now < start) {
    return 0;
  }

  return Math.floor(
    ((now.getTime() - start.getTime()) / (end.getTime() - start.getTime())) *
      100
  );
}

export function calculateMonthsBetweenDates(start: Date, end: Date) {
  return (
    end.getMonth() -
    start.getMonth() +
    12 * (end.getFullYear() - start.getFullYear())
  );
}

export function currentTotalCostLabels(retainers: any) {
  if (retainers) {
    const {
      billedSpend,
      pendingSpend,
      billedOverage,
      pendingOverage,
      totalActiveRetainerAmount,
    } = retainers;
    const billedSpendFormatted =
      numberFormatterWithCurrency.format(billedSpend);
    const pendingSpendFormatted =
      numberFormatterWithCurrency.format(pendingSpend);

    const billedOverageFormatted =
      numberFormatterWithCurrency.format(billedOverage);
    const pendingOverageFormatted =
      numberFormatterWithCurrency.format(pendingOverage);

    const pendingSpendFromOverageFormatted = numberFormatterWithCurrency.format(
      pendingSpend - pendingOverage
    );

    const spendPercentageFormatted = percentageFormatter.format(
      (billedSpend + pendingSpend) / totalActiveRetainerAmount
    );
    const remainingFormatted = numberFormatterWithCurrency.format(
      totalActiveRetainerAmount - (billedSpend + pendingSpend)
    );
    const totalActiveRetainerAmountFormatted =
      numberFormatterWithCurrency.format(totalActiveRetainerAmount);
    const totalBilledPendingFormatted = numberFormatterWithCurrency.format(
      billedSpend + pendingSpend
    );
    return {
      remainingFormatted,
      billedSpendFormatted,
      pendingSpendFormatted,
      billedOverageFormatted,
      pendingOverageFormatted,
      spendPercentageFormatted,
      totalBilledPendingFormatted,
      pendingSpendFromOverageFormatted,
      totalActiveRetainerAmountFormatted,
    };
  } else {
    return {
      remainingFormatted: 0,
      billedSpendFormatted: 0,
      pendingSpendFormatted: 0,
      billedOverageFormatted: 0,
      pendingOverageFormatted: 0,
      spendPercentageFormatted: 0,
      totalBilledPendingFormatted: 0,
      pendingSpendFromOverageFormatted: 0,
      totalActiveRetainerAmountFormatted: 0,
    };
  }
}
