import { useEffect, useMemo, useState } from "react";

const useDataGridStickyHeader = (
  autoHeight: boolean,
  gridId: string | undefined,
) => {
  let stickyDiv = document.querySelector<HTMLElement>(
    ".MuiDataGrid-scrollbar--horizontal",
  );
  const parentContainer =
    document.querySelector<HTMLElement>(".MuiDataGrid-main");
  const container = document.getElementById("main-container");

  const [parentContainerHeight, setParentContainerHeight] = useState<number>(
    parentContainer?.getBoundingClientRect()?.height,
  );
  const [parentContainerWidth, setParentContainerWidth] = useState<number>(
    parentContainer?.getBoundingClientRect()?.width,
  );
  const [filterChipsBar, setFilterChipsBar] = useState<Element | null>(null);

  const [headerContainer, setHeaderContainer] = useState<HTMLElement | null>(
    null,
  );

  //Observe child elements' existences in DOM, includes header container and filter chips bar
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          setFilterChipsBar(document.getElementById("filter-chips-bar"));
          setHeaderContainer(
            document.querySelector<HTMLElement>(".MuiDataGrid-topContainer"),
          );
        }
      });
    });
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(document.body, config);

    return () => observer.disconnect();
  }, []);
  const headerContainerRectHeight = headerContainer?.clientHeight;

  // Memorize the initial distance between the data grid container and the top viewport
  const parentContainerRectTop = useMemo(() => {
    return parentContainer?.getBoundingClientRect()?.top + container?.scrollTop;
  }, [parentContainer, filterChipsBar]);

  // Observe when the data grid container resize to get its height and width and adjust the horizontal scrollbar position
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { height, width } = entries[0].contentRect;
      setParentContainerHeight(height);
      setParentContainerWidth(width);
      handleGridHorizontalScrollbar(height, width, container?.scrollTop);
    });
    if (parentContainer) observer.observe(parentContainer);
    return () => {
      observer.disconnect();
    };
  }, [parentContainer]);

  // Scroll EventListener, adjust position of the header container and the horizontal scrollbar container position.
  useEffect(() => {
    const handleScroll = () => {
      if (autoHeight) {
        const scrollTop = container?.scrollTop ?? 0;
        const scrollDifference = Math.max(
          scrollTop + headerContainerRectHeight - 5 - parentContainerRectTop,
          0,
        );

        if (headerContainer) {
          if (parentContainerRectTop - headerContainerRectHeight < scrollTop) {
            headerContainer.style.top = scrollDifference + "px";
          } else {
            headerContainer.style.top = "0px";
          }
        }
        handleGridHorizontalScrollbar(
          parentContainerHeight,
          parentContainerWidth,
          scrollTop,
        );
      }
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [
    headerContainerRectHeight,
    parentContainerWidth,
    parentContainerHeight,
    gridId,
    parentContainerRectTop,
  ]);

  const handleGridHorizontalScrollbar = (
    currentHeight: number,
    currentWidth: number,
    scrollTop = 0,
  ) => {
    if (stickyDiv) {
      const scrollContainerHeight = window.innerHeight;
      const bottomContToTopView = currentHeight + parentContainerRectTop;
      if (
        scrollContainerHeight < bottomContToTopView &&
        scrollTop + scrollContainerHeight < bottomContToTopView
      ) {
        stickyDiv.style.position = "fixed";
        stickyDiv.style.bottom = "0px";
        stickyDiv.style.width = currentWidth + "px";
      } else {
        stickyDiv.style.position = "absolute";
        stickyDiv.style.width = "100%";
      }
    }
  };
};
export default useDataGridStickyHeader;
